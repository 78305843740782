.item-container{
	border-radius: 5px;
	box-shadow: 2px 3px 8px #4e4e4e;
    color: black;
    padding: 10px;
    margin: 10px;
    background: #f5f5f5;
/*    max-width: 750px;*/
}
.item-container p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.item-title{
    margin-bottom: 15px!important;
    font-size: 22px;
	font-weight: bold;
}

.dqa-week-text{
    font-size: 20px;
/*    color: #00bd70;*/
}

.CircularProgressbar {
    width: 75%!important;
    max-height: 250px;
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: #00bd70!important;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: #00bd70!important;
}

.error-display-datepicker {
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 100%;
}
.error-display-datepicker-container {

}
.error-display-button {
    color: white;
    background: #00bd70;
    width: 215px;
    cursor: pointer;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    -webkit-transition: all .2s;
    transition: all .2s;
    border: none;
}
.error-display-button:hover {
    background: #019257;
}
.error-display-button:focus {
    outline: none;
}
.error-display-button-container {
    margin:10px;
}
.error-display-alert-container {
    background: #e6e6e6;
    margin: 20px;
    padding: 10px;
    border-radius: 3px;
}
.error-display-alert-number {
    display: inline-block;
    font-size: 32px;
    font-weight: bold;
    padding-right: 10px;
}
.error-display-alert-text {
    display: inline-block;
}

.processing-error-details-header {
    color: red;
}
.processing-error-details-text {
    white-space: pre-wrap;
}
