.datepicker-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.modal-submit-button {
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ffffff;
    background: #00bd70;
    width: 100%;
    cursor: pointer;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
}
.modal-submit-button:disabled {
    background: grey;
}

.modal-submit-button:hover {
    background: #019257;
    transition: all .2s;
}

.modal-select {
    width: 100%;
    height: 30px;
    outline: none!important;
}

.modal-title {
    margin: 0px 40px 20px 0px;
    font-size: 18px;
}

.modal-subtitle {
    font-size: 16px;
    margin: 5px 0px 5px 0px;
}

.scheduled-assignment-change-table {
    background: #f0f0f0;
    box-shadow: none;
}

.scheduled-assignment-change-table td{
    font-size: 16px;
}