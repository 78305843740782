.App {
  text-align: center;
  height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.main-content {
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.load-overlay{
  opacity:0.4;
/*  filter: alpha(opacity=20);*/
  background-color:#000; 
  width:100%; 
  height:100%; 
  z-index:9999;
  top:0; 
  left:0; 
  position:fixed; 
}

.centered-div {
  opacity: 0.9;
  z-index:9999!important;
  position: absolute;
  width: 150px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: 0px 0 0 0px;
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 4px 8px 15px #00000066;
}

.centered-div p {
  text-align: left;
  margin: 17px;
  color: #0d1b2a;
  font-size: 16px;
  font-weight: bold;
}

.load-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0D1B2A;
}

/* ----- SIDEBAR ----- */

.sidebar {
  float: left;
/*  position: absolute;
  left: 0px;*/
  height:100%;
  width: 60px;
  box-shadow: 1px 0px 10px #404040;
}
.sidebar-profile-container {
  display: none;
  width:100%;
  height:110px;
  margin-top: 20px;
  border-bottom: 2px solid #3f454a;
}
.sidebar-profile-image {
  background: #c5c5c5;
  width:50px;
  height:50px;
  border-radius:80px;
  margin: 0 auto;
}
.sidebar-profile-name {
  font-size: 14px;
  margin-top: 5px;
  color: #d6dae0;
}
.sidebar-profile-role {
  font-size: 10px;
  color: #d6dae0;
}
.sidebar-button-container {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
  height: 20px;
}
.sidebar-button-selection-indicator {
  height: 100%;
  width: 3px;
  background-color: #00bd70;
  float: left;
}
.sidebar-button {
  height: 100%;
  text-align: left;
  color: #d6dae0;
  margin-left: 20px;
  cursor: pointer;
}
.sidebar-button-label {
  font-size: 15px;
  display: none;
}
.sidebar-button-icon {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .sidebar {
    width: 175px;
  }
  .sidebar-profile-container {
    display: inline-block;
    height: 170px;
  }
  .sidebar-profile-image {
    width:100px;
    height:100px;
    border-radius:100px;
  }
  .sidebar-profile-name {
    font-size: 18px;
  }
  .sidebar-profile-role {
    font-size: 12px;
  }
  .sidebar-button-label{
    display: inline;
  }
}

/* ----- Login Modal ----- */

.modal-bg-overlay {
  position: relative;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.modal-login-container {
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 280px;
  background: #ffffff;
  box-shadow: 12px 0px 15px 0 rgba(0, 0, 0, 0.25);
}
.modal-login-header p{
  color: #0d1b2a;
  font-size: 22px;
}
.modal-login-body{
  text-align: left;
  margin: 10px;
/*  padding-top: 10px;*/
}
.modal-login-body p{
  color: #0d1b2a;
  font-size: 14px;
  margin:0px;
}
.modal-login-body input{
  font-size: 14px;
  width: 275px;
  margin-top: 10px;
  border: none;
  background: #eaeaea;
  height: 35px;
  border-radius: 3px;
  padding-left: 5px;
}
.modal-login-body input:hover {
  background: #e4e4e4;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.modal-login-body input:focus {
  outline:none;
}

.modal-login-button {
  outline: none;
  margin-top: 30px;
  color: #ffffff;
  background: #00bd70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}
.modal-login-button:hover {
  background: #019257;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.modal-login-button:disabled {
  background: gray;
}
.modal-login-body a {
  font-size: 10px;
}
.modal-login-spinner {
  -webkit-animation: fa-spin 1.5s infinite linear;
  animation: fa-spin 1.5s infinite linear;
  height: 16px;
}

.confmodal-addrow-container {
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 500px;
    background: #ffffff;
    box-shadow: 12px 0px 15px 0 rgb(0 0 0 / 25%);
}

.confmodal-addrow-checkbox {
    height: 20px!important;
    width: 20px!important;
    margin: 0px 0px 0px 10px!important;
    position: absolute;
    right: 10px;
}

.modal-addrow-container{
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 200px;
  background: #ffffff;
  box-shadow: 12px 0px 15px 0 rgba(0, 0, 0, 0.25);
}

.modal-addrow-select {
  width: 100%;
  margin-top: 10px;
  height: 30px;
  outline: none!important;
}

.modal-addrow-button {
  outline: none;
  margin-top: 30px;
  margin: 10px;
  color: #ffffff;
  background: #00bd70;
  width: 120px;
  cursor: pointer;
  padding: 5px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}
.modal-addrow-button:hover {
  background: #019257;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.modal-addrow-button:disabled {
  background: gray;
}

.datastream-addrow-button{
  height: 30px;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  width: 183px;
  padding: 5px;
  margin: 10px;
  background: #00bd70;
  color: white;
  border-radius: 5px;
}
.datastream-addrow-button:hover {
  background: #019257;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.datastream-addrow-button:disabled {
  background: gray;
}

.datastream-addrow-button p{
  float: left;
  margin: 0;
}

.modal-adddatastreams-textarea {
  resize: none;
  width: 275px;
  height: 125px; 
  outline: none;
}
