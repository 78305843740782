@keyframes anim-glow {
   0% {
     box-shadow: 0 0 rgba(97, 239, 97, 1);
  }
   100% {
     box-shadow: 0 0 10px 8px transparent;
     border-width: 2px;
  }
}

@keyframes anim-glow-red {
   0% {
     box-shadow: 0 0 rgb(195, 91, 91);
  }
   100% {
     box-shadow: 0 0 10px 8px transparent;
     border-width: 2px;
  }
}

.config-table-container {
  display: block;
  position:absolute;
  height:auto;
  bottom:0;
  top:0;
  left:0;
  right:0;
  margin-top:20px;
  margin-bottom:50px;
  margin-right:20px;
  margin-left:120px; 
}

@media only screen and (min-width: 768px) {
	.config-table-container{
		margin-left:200px;
	}
}

.glow-indicator {
  cursor: pointer;
  display: inline-block;
   border-radius: 50%;
   background: #61ef61;
   border: 2px solid #5fe85f;
   width: 10px;
   height: 10px;
   text-align: center;
   position: relative;
   animation: anim-glow 2s ease infinite;
}

.disabled{
  background: #b30000!important;
  border: 2px solid #b30000!important;
  animation: anim-glow-red 2s ease infinite;
}


.config-table-delete{
  cursor: pointer;
  color: rgb(195, 91, 91);
}
.config-table-delete:hover{
  color: #b30000;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}