
.top-div {
    background: #0d1b2a;
    padding: 5px;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.legend-div {
	float: right;
	margin-right: 10px;
/*	font-size: 10px;*/
}
.legend-div p{
	    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: white;
}

.legend-item {
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 5px;
	border-radius: 3px;
}

.dqprocesschart-container {
    height: 100vh;
    box-sizing: border-box;
    padding:20px;
    overflow-x: auto;
}

.submit-button {
  cursor: pointer;
    width: 100px;
	 border: none;
    font-size: 14px;
    height: 30px;
    border-radius: 3px;
    color: white;
    float: right;
    background: #00bd70;
}

.legend-button {
  cursor: pointer;
    width: 100px;
    border: none;
    font-size: 14px;
    height: 40px;
    border-radius: 3px;
    color: white;
    float: right;
    background: #888888;
}

.legend-tooltip {
  width: 200px;
  height: 250px;
}

.color-box {
    float: left;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #ccc;
    border-radius: 3px;
}
.color-description {
    width: 170px;
    text-align: left;
    margin-left: 30px;
/*  margin-left: 20px;*/
/*  display: inline-block;*/
/*  float: right;*/
}

.filter-box {
    background: #f5f5f5!important;
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 100%;
}

.filter-select-container {
    display: inline-block;
    float: left;
    margin-top: 10px;
    clear: both;
    width: 420px;
}

table {
    width:100%;
    background: #dadbdc;
    box-shadow: 1px 0px 6px #404040;
}
table, th, td, tr {
  border: 1px solid #3f454a;
  border-collapse: collapse;
  font-weight: normal;
}
th, td {
  padding: 5px;
  text-align: left;    
}
.tooltip-cell {
  cursor: pointer;
}

.tippy-class {
  display: block!important;
}


table, th, td, tr {
  font-size: 1.3vw;
}
@media screen and (min-width: 1600px) {
  table, th, td, tr {
     font-size: 20px;
  }
}

tr:nth-child(even) {background-color: #f2f2f2;}


.process-tooltip h3 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  padding: 0px 0px 5px 0px;
  text-decoration: underline;
}

.process-tooltip h2 {
  font-size: 20px;
  text-align: left;
  margin: 0px;
  padding: 0px 0px 5px 0px;
}



.process-tooltip li {
  
}

.process-tooltip ul {
  text-align: left;
  list-style-type: none;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}

.process-tooltip-section {
  padding: 5px;
}

.process-tooltip-section p{
  font-size: 12px;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.process-tooltip-section a{
  background-color: #0d1b2a;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
}

.process-tooltip-section a:hover{
  background-color: #060d15;
}
.process-tooltip-section a:active {
  background-color: #060d15;
}

.process-details-loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
.ProcessStatusDisplay {
  white-space: pre-wrap;
}


.Collapsible {
  background-color: white; }

.Collapsible ul {
  list-style-type: none;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  cursor: pointer;
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #0d1b2a;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }