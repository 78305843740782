@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.item-container{
	border-radius: 5px;
	box-shadow: 2px 3px 8px #4e4e4e;
    color: black;
    padding: 10px;
    margin: 10px;
    background: #f5f5f5;
/*    max-width: 750px;*/
}
.item-container p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.item-title{
    margin-bottom: 15px!important;
    font-size: 22px;
	font-weight: bold;
}

.dqa-week-text{
    font-size: 20px;
/*    color: #00bd70;*/
}

.CircularProgressbar {
    width: 75%!important;
    max-height: 250px;
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: #00bd70!important;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: #00bd70!important;
}

.error-display-datepicker {
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 100%;
}
.error-display-datepicker-container {

}
.error-display-button {
    color: white;
    background: #00bd70;
    width: 215px;
    cursor: pointer;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    transition: all .2s;
    border: none;
}
.error-display-button:hover {
    background: #019257;
}
.error-display-button:focus {
    outline: none;
}
.error-display-button-container {
    margin:10px;
}
.error-display-alert-container {
    background: #e6e6e6;
    margin: 20px;
    padding: 10px;
    border-radius: 3px;
}
.error-display-alert-number {
    display: inline-block;
    font-size: 32px;
    font-weight: bold;
    padding-right: 10px;
}
.error-display-alert-text {
    display: inline-block;
}

.processing-error-details-header {
    color: red;
}
.processing-error-details-text {
    white-space: pre-wrap;
}

.progresschart-container {
  padding: 20px;
}

.progresschart-select-container{
 margin-bottom: 40px;
}
.progresschart-select{
  background: #f5f5f5;
  border-radius: 3px;
  padding: 3px;
  font-size: inherit;
  width: 200px;
  float: left;
  border:1px solid rgba(0,0,0,0.1);
  outline:none!important;
}

.complete-dqa {
  background-color: green!important;
  border:1px solid rgba(0,0,0,0.5)!important;
}

.incomplete-dqa {
  background-color: red!important;
  border:none!important;
}

/*.react-calendar-timeline * {
  box-sizing: border-box; }
*/
.react-calendar-timeline .rct-header-root {
  background: #0d1b2a!important;
}
/*
.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap; }

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: scroll;
  -ms-touch-action: none;
  touch-action: none; }

.react-calendar-timeline .rct-item:hover {
  z-index: 88; }

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%; }

.react-calendar-timeline .rct-header-container {
  z-index: 90;
  display: flex;
  overflow: hidden; }
  .react-calendar-timeline .rct-header-container.header-sticky {
    position: sticky;
    position: -webkit-sticky; }

.react-calendar-timeline .rct-header {
  border-top-right-radius: 3px;
  margin: 0;
  overflow-x: hidden;
  z-index: 90; }
  .react-calendar-timeline .rct-header .rct-top-header,
  .react-calendar-timeline .rct-header .rct-bottom-header {
    position: relative; }
  .react-calendar-timeline .rct-header .rct-label-group {
    padding: 0 5px;
    position: absolute;
    top: 0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border-left: 2px solid #bbb;
    color: #ffffff;
    background: #0d1b2a;
    border-bottom: 1px solid #bbb;
    cursor: pointer; }
    .react-calendar-timeline .rct-header .rct-label-group.rct-has-right-sidebar {
      border-right: 1px solid #bbb;
      border-left: 1px solid #bbb; }
    .react-calendar-timeline .rct-header .rct-label-group > span {
      position: sticky;
      left: 5px;
      right: 5px; }
  .react-calendar-timeline .rct-header .rct-label {
    position: absolute;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #bbb;
    color: #333333;
    background: #f0f0f0;
    border-bottom: 1px solid #bbb;
    cursor: pointer; }
    .react-calendar-timeline .rct-header .rct-label.rct-label-only {
      color: #ffffff;
      background: #0d1b2a; }
    .react-calendar-timeline .rct-header .rct-label.rct-first-of-type {
      border-left: 2px solid #bbb; }

.react-calendar-timeline .rct-sidebar-header {
  border-top-left-radius: 3px;
  margin: 0;
  color: #ffffff;
  background: #0d1b2a;
  border-right: 1px solid #bbb;
  box-sizing: border-box;
  border-bottom: 1px solid #bbb;
  overflow: hidden; }
  .react-calendar-timeline .rct-sidebar-header.rct-sidebar-right {
    border-right: 0;
    border-left: 1px solid #bbb; }

.react-calendar-timeline .rct-sidebar {
      border-bottom-left-radius: 3px;
    border-left: 1px solid #bbb;
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #bbb; }
  .react-calendar-timeline .rct-sidebar.rct-sidebar-right {
    border-right: 0;
    border-left: 1px solid #bbb; }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: content-box;
    margin: 0;
    border-bottom: 1px solid #bbb; }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
      background: rgba(0, 0, 0, 0.05); }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
      background: transparent; }

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid #bbb;
  z-index: 30; }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 2px; }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(250, 246, 225, 0.5); }

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #bbb;
  box-sizing: content-box;
  z-index: 40; }

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(0, 0, 0, 0.05); }

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: transparent; }

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  background: #2196f3;
  z-index: 51; }

.react-calendar-timeline .rct-infolabel {
  position: fixed;
  left: 100px;
  bottom: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  font-size: 20px;
  border-radius: 5px;
  z-index: 85; }
*/
.datepicker-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.modal-submit-button {
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ffffff;
    background: #00bd70;
    width: 100%;
    cursor: pointer;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
}
.modal-submit-button:disabled {
    background: grey;
}

.modal-submit-button:hover {
    background: #019257;
    transition: all .2s;
}

.modal-select {
    width: 100%;
    height: 30px;
    outline: none!important;
}

.modal-title {
    margin: 0px 40px 20px 0px;
    font-size: 18px;
}

.modal-subtitle {
    font-size: 16px;
    margin: 5px 0px 5px 0px;
}

.scheduled-assignment-change-table {
    background: #f0f0f0;
    box-shadow: none;
}

.scheduled-assignment-change-table td{
    font-size: 16px;
}
.rt-th {
	outline:none!important;
	text-align: left;
}

.rt-th select{
	height: 30px;
}

.assignment-table-container{
/*	padding:20px; */
  display: block;
  position:absolute;
  height:auto;
  bottom:0;
  top:0;
  left:0;
  right:0;
  margin-top:20px;
  margin-bottom:50px;
  margin-right:20px;
  margin-left:120px;
}

.assignment-table-select{
	font-size: inherit;
	width: calc(100% - 20px);
	border:1px solid rgba(0,0,0,0.1);
	outline:none!important;
  margin-right: 5px;
}

.assignment-table-select-cal-icon {
  cursor: pointer;
  color: #0d1b2a;
}

.assignment-table-delete{
  cursor: pointer;
  color: red;
}
.assignment-table-delete:hover{
  color: #b30000;
  transition: all .2s;
}

.add-row-button{
    font-size: 14px;
  cursor: pointer;
  background: #00bd70;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  bottom: 10px;
  left: 200px;
  color: white;
}
.add-row-button:hover {
  background: #019257;
  transition: all .2s;
}
.add-row-button p{
  margin: 0;
  float: right;
  padding-left: 10px;

}

@media only screen and (min-width: 768px) {
	.assignment-table-container{
		margin-left:200px;
	}
}

.top-div {
    background: #0d1b2a;
    padding: 5px;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.legend-div {
	float: right;
	margin-right: 10px;
/*	font-size: 10px;*/
}
.legend-div p{
	    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: white;
}

.legend-item {
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 5px;
	border-radius: 3px;
}

.dqprocesschart-container {
    height: 100vh;
    box-sizing: border-box;
    padding:20px;
    overflow-x: auto;
}

.submit-button {
  cursor: pointer;
    width: 100px;
	 border: none;
    font-size: 14px;
    height: 30px;
    border-radius: 3px;
    color: white;
    float: right;
    background: #00bd70;
}

.legend-button {
  cursor: pointer;
    width: 100px;
    border: none;
    font-size: 14px;
    height: 40px;
    border-radius: 3px;
    color: white;
    float: right;
    background: #888888;
}

.legend-tooltip {
  width: 200px;
  height: 250px;
}

.color-box {
    float: left;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #ccc;
    border-radius: 3px;
}
.color-description {
    width: 170px;
    text-align: left;
    margin-left: 30px;
/*  margin-left: 20px;*/
/*  display: inline-block;*/
/*  float: right;*/
}

.filter-box {
    background: #f5f5f5!important;
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 100%;
}

.filter-select-container {
    display: inline-block;
    float: left;
    margin-top: 10px;
    clear: both;
    width: 420px;
}

table {
    width:100%;
    background: #dadbdc;
    box-shadow: 1px 0px 6px #404040;
}
table, th, td, tr {
  border: 1px solid #3f454a;
  border-collapse: collapse;
  font-weight: normal;
}
th, td {
  padding: 5px;
  text-align: left;    
}
.tooltip-cell {
  cursor: pointer;
}

.tippy-class {
  display: block!important;
}


table, th, td, tr {
  font-size: 1.3vw;
}
@media screen and (min-width: 1600px) {
  table, th, td, tr {
     font-size: 20px;
  }
}

tr:nth-child(even) {background-color: #f2f2f2;}


.process-tooltip h3 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  padding: 0px 0px 5px 0px;
  text-decoration: underline;
}

.process-tooltip h2 {
  font-size: 20px;
  text-align: left;
  margin: 0px;
  padding: 0px 0px 5px 0px;
}



.process-tooltip li {
  
}

.process-tooltip ul {
  text-align: left;
  list-style-type: none;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}

.process-tooltip-section {
  padding: 5px;
}

.process-tooltip-section p{
  font-size: 12px;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.process-tooltip-section a{
  background-color: #0d1b2a;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
}

.process-tooltip-section a:hover{
  background-color: #060d15;
}
.process-tooltip-section a:active {
  background-color: #060d15;
}

.process-details-loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}
.ProcessStatusDisplay {
  white-space: pre-wrap;
}


.Collapsible {
  background-color: white; }

.Collapsible ul {
  list-style-type: none;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  cursor: pointer;
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #0d1b2a;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }
.cancel-button {
  background: #888888!important;
}
.cancel-button:hover {
  background: #636363!important;
}
@-webkit-keyframes anim-glow {
   0% {
     box-shadow: 0 0 rgba(97, 239, 97, 1);
  }
   100% {
     box-shadow: 0 0 10px 8px transparent;
     border-width: 2px;
  }
}

@keyframes anim-glow {
   0% {
     box-shadow: 0 0 rgba(97, 239, 97, 1);
  }
   100% {
     box-shadow: 0 0 10px 8px transparent;
     border-width: 2px;
  }
}

@-webkit-keyframes anim-glow-red {
   0% {
     box-shadow: 0 0 rgb(195, 91, 91);
  }
   100% {
     box-shadow: 0 0 10px 8px transparent;
     border-width: 2px;
  }
}

@keyframes anim-glow-red {
   0% {
     box-shadow: 0 0 rgb(195, 91, 91);
  }
   100% {
     box-shadow: 0 0 10px 8px transparent;
     border-width: 2px;
  }
}

.config-table-container {
  display: block;
  position:absolute;
  height:auto;
  bottom:0;
  top:0;
  left:0;
  right:0;
  margin-top:20px;
  margin-bottom:50px;
  margin-right:20px;
  margin-left:120px; 
}

@media only screen and (min-width: 768px) {
	.config-table-container{
		margin-left:200px;
	}
}

.glow-indicator {
  cursor: pointer;
  display: inline-block;
   border-radius: 50%;
   background: #61ef61;
   border: 2px solid #5fe85f;
   width: 10px;
   height: 10px;
   text-align: center;
   position: relative;
   -webkit-animation: anim-glow 2s ease infinite;
           animation: anim-glow 2s ease infinite;
}

.disabled{
  background: #b30000!important;
  border: 2px solid #b30000!important;
  -webkit-animation: anim-glow-red 2s ease infinite;
          animation: anim-glow-red 2s ease infinite;
}


.config-table-delete{
  cursor: pointer;
  color: rgb(195, 91, 91);
}
.config-table-delete:hover{
  color: #b30000;
  transition: all .2s;
}
.App {
  text-align: center;
  height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.main-content {
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.load-overlay{
  opacity:0.4;
/*  filter: alpha(opacity=20);*/
  background-color:#000; 
  width:100%; 
  height:100%; 
  z-index:9999;
  top:0; 
  left:0; 
  position:fixed; 
}

.centered-div {
  opacity: 0.9;
  z-index:9999!important;
  position: absolute;
  width: 150px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: 0px 0 0 0px;
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 4px 8px 15px #00000066;
}

.centered-div p {
  text-align: left;
  margin: 17px;
  color: #0d1b2a;
  font-size: 16px;
  font-weight: bold;
}

.load-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0D1B2A;
}

/* ----- SIDEBAR ----- */

.sidebar {
  float: left;
/*  position: absolute;
  left: 0px;*/
  height:100%;
  width: 60px;
  box-shadow: 1px 0px 10px #404040;
}
.sidebar-profile-container {
  display: none;
  width:100%;
  height:110px;
  margin-top: 20px;
  border-bottom: 2px solid #3f454a;
}
.sidebar-profile-image {
  background: #c5c5c5;
  width:50px;
  height:50px;
  border-radius:80px;
  margin: 0 auto;
}
.sidebar-profile-name {
  font-size: 14px;
  margin-top: 5px;
  color: #d6dae0;
}
.sidebar-profile-role {
  font-size: 10px;
  color: #d6dae0;
}
.sidebar-button-container {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
  height: 20px;
}
.sidebar-button-selection-indicator {
  height: 100%;
  width: 3px;
  background-color: #00bd70;
  float: left;
}
.sidebar-button {
  height: 100%;
  text-align: left;
  color: #d6dae0;
  margin-left: 20px;
  cursor: pointer;
}
.sidebar-button-label {
  font-size: 15px;
  display: none;
}
.sidebar-button-icon {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .sidebar {
    width: 175px;
  }
  .sidebar-profile-container {
    display: inline-block;
    height: 170px;
  }
  .sidebar-profile-image {
    width:100px;
    height:100px;
    border-radius:100px;
  }
  .sidebar-profile-name {
    font-size: 18px;
  }
  .sidebar-profile-role {
    font-size: 12px;
  }
  .sidebar-button-label{
    display: inline;
  }
}

/* ----- Login Modal ----- */

.modal-bg-overlay {
  position: relative;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.modal-login-container {
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 280px;
  background: #ffffff;
  box-shadow: 12px 0px 15px 0 rgba(0, 0, 0, 0.25);
}
.modal-login-header p{
  color: #0d1b2a;
  font-size: 22px;
}
.modal-login-body{
  text-align: left;
  margin: 10px;
/*  padding-top: 10px;*/
}
.modal-login-body p{
  color: #0d1b2a;
  font-size: 14px;
  margin:0px;
}
.modal-login-body input{
  font-size: 14px;
  width: 275px;
  margin-top: 10px;
  border: none;
  background: #eaeaea;
  height: 35px;
  border-radius: 3px;
  padding-left: 5px;
}
.modal-login-body input:hover {
  background: #e4e4e4;
  transition: all .2s;
}
.modal-login-body input:focus {
  outline:none;
}

.modal-login-button {
  outline: none;
  margin-top: 30px;
  color: #ffffff;
  background: #00bd70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}
.modal-login-button:hover {
  background: #019257;
  transition: all .2s;
}
.modal-login-button:disabled {
  background: gray;
}
.modal-login-body a {
  font-size: 10px;
}
.modal-login-spinner {
  -webkit-animation: fa-spin 1.5s infinite linear;
  animation: fa-spin 1.5s infinite linear;
  height: 16px;
}

.confmodal-addrow-container {
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 500px;
    background: #ffffff;
    box-shadow: 12px 0px 15px 0 rgb(0 0 0 / 25%);
}

.confmodal-addrow-checkbox {
    height: 20px!important;
    width: 20px!important;
    margin: 0px 0px 0px 10px!important;
    position: absolute;
    right: 10px;
}

.modal-addrow-container{
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 200px;
  background: #ffffff;
  box-shadow: 12px 0px 15px 0 rgba(0, 0, 0, 0.25);
}

.modal-addrow-select {
  width: 100%;
  margin-top: 10px;
  height: 30px;
  outline: none!important;
}

.modal-addrow-button {
  outline: none;
  margin-top: 30px;
  margin: 10px;
  color: #ffffff;
  background: #00bd70;
  width: 120px;
  cursor: pointer;
  padding: 5px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}
.modal-addrow-button:hover {
  background: #019257;
  transition: all .2s;
}
.modal-addrow-button:disabled {
  background: gray;
}

.datastream-addrow-button{
  height: 30px;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  width: 183px;
  padding: 5px;
  margin: 10px;
  background: #00bd70;
  color: white;
  border-radius: 5px;
}
.datastream-addrow-button:hover {
  background: #019257;
  transition: all .2s;
}
.datastream-addrow-button:disabled {
  background: gray;
}

.datastream-addrow-button p{
  float: left;
  margin: 0;
}

.modal-adddatastreams-textarea {
  resize: none;
  width: 275px;
  height: 125px; 
  outline: none;
}

