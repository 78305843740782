.rt-th {
	outline:none!important;
	text-align: left;
}

.rt-th select{
	height: 30px;
}

.assignment-table-container{
/*	padding:20px; */
  display: block;
  position:absolute;
  height:auto;
  bottom:0;
  top:0;
  left:0;
  right:0;
  margin-top:20px;
  margin-bottom:50px;
  margin-right:20px;
  margin-left:120px;
}

.assignment-table-select{
	font-size: inherit;
	width: calc(100% - 20px);
	border:1px solid rgba(0,0,0,0.1);
	outline:none!important;
  margin-right: 5px;
}

.assignment-table-select-cal-icon {
  cursor: pointer;
  color: #0d1b2a;
}

.assignment-table-delete{
  cursor: pointer;
  color: red;
}
.assignment-table-delete:hover{
  color: #b30000;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.add-row-button{
    font-size: 14px;
  cursor: pointer;
  background: #00bd70;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  bottom: 10px;
  left: 200px;
  color: white;
}
.add-row-button:hover {
  background: #019257;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.add-row-button p{
  margin: 0;
  float: right;
  padding-left: 10px;

}

@media only screen and (min-width: 768px) {
	.assignment-table-container{
		margin-left:200px;
	}
}